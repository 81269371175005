<script>
import Axios from "axios";
import RepairerViewDetails from "./repairer-view/details";
import RepairerViewContacts from "./repairer-view/contacts";
import RepairerViewLocations from "./repairer-view/locations";
import RepairerViewRepairsAssigned from "./repairer-view/assigned";

export default {
  name: 'RepairerView',
  components: {
    RepairerViewDetails,
    RepairerViewContacts,
    RepairerViewLocations,
    RepairerViewRepairsAssigned,
  },
  data(){
    return {
      repairer: {},
      repairs: [],
      currentTab: '',
    };
  },
  computed: {
    repairerName: {
      get(){
        if(this.repairer.businessName == 'New Repairer'){
          return '';
        }
        return this.repairer.businessName;
      },
      set(val){
        if(val === ''){
          this.repairer.businessName = 'New Repairer';
        } else {
          this.repairer.businessName = val;
        }
      }
    },
  },
  mounted() {
    this.loadRepairer();
  },
  methods: {
    loadRepairer(callback = null){
      NProgress.start()
      Axios.get(`/ir/repairer/${this.$route.params.repairerId}`)
        .then(res => {
          if(res.data && res.data._status){
            res.data.data.locations.forEach(l => { l.edit = false; });
            res.data.data.contacts .forEach(c => { c.edit = false; });
            this.repairer = res.data.data;
            this.repairs  = res.data.repairs;
            if(callback) callback();
          }
        })
        .catch(e => console.error(e))
        .finally(() => NProgress.done());
    },
    saveRepairer(){
      NProgress.start()
      Axios.post(`/ir/repairer/${this.$route.params.repairerId}`, this.repairer)
          .then(res => {
            if(res.data && res.data._status){
              this.$toast.success("Repairer data updated successfully");
            }
          })
          .catch(e => {
            console.error(e)
            this.$toast.error("Cannot update repairer data");
          })
          .finally(() => NProgress.done());
    },
    onTabChange(e){
      this.currentTab = e.tab.name;
    },
    addContact(){
      Axios.post(`/ir/repairer/${this.repairer.id}/add-contact`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success("New Contact Added");
              this.loadRepairer(() => {
                this.repairer.contacts?.forEach(c => {
                  if(c.id == response.data.contactId){
                    c.edit = true;
                  }
                })
              });
            }
          })
          .catch();
    },
    addLocation(){
      Axios.post(`/ir/repairer/${this.repairer.id}/add-location`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success("New Location Added");
              this.loadRepairer(() => {
                this.repairer.locations?.forEach(l => {
                  if(l.id == response.data.locationId){
                    l.edit = true;
                  }
                })
              });
            }
          })
          .catch();
    },
  },
}
</script>

<template>
  <div class="settings-page">
    <div class="page-header">
      <h4>{{ repairerName }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/repairers">Repairers</router-link>
        </li>
        <li class="breadcrumb-item active">
          {{ repairerName }}
        </li>
      </ol>
      <div class="page-header-nav-btn">
        <a @click="saveRepairer" class="btn btn-primary btn-submit-quote">
          <span>Save</span>
        </a>
      </div>

    </div>
    <div class="repairer-view-body">

      <tabs ref="repairer-view-main-table" cache-lifetime="0">
        <tab name="Business Details" id="repairer-view-business" :class-name="'tab-content-table'">

          <tabs ref="repairer-view-table" cache-lifetime="0" @changed="onTabChange">
            <tab name="Details" id="repairer-view-details" ref="repairerViewDetailsTab" :class-name="'tab-content-table'">
              <repairer-view-details :repairer="repairer"></repairer-view-details>
            </tab>
            <tab name="Contacts" id="repairer-view-contacts" :class-name="'tab-content-table'">
              <repairer-view-contacts :contacts="repairer.contacts" :locations="repairer.locations" @contact-updated="loadRepairer"></repairer-view-contacts>
            </tab>
            <tab name="Locations" id="repairer-view-locations" :class-name="'tab-content-table'">
              <repairer-view-locations :locations="repairer.locations" @location-updated="loadRepairer"></repairer-view-locations>
            </tab>
            <template slot="nav-item-right">
              <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Contacts'">
                <a @click="addContact" class="btn btn-primary btn-submit-quote">
                  <i class='bx bx-plus'></i><span>Add Contact</span>
                </a>
              </div>
              <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Locations'">
                <a @click="addLocation" class="btn btn-primary btn-submit-quote">
                  <i class='bx bx-plus'></i><span>Add Location</span>
                </a>
              </div>

              <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Details'">
                <a @click="saveRepairer" class="btn btn-primary btn-submit-quote">
                  <span>Save</span>
                </a>
              </div>
            </template>
          </tabs>

        </tab>
        <tab name="Repairs Assigned" id="repairer-view-assigned" ref="repairerViewDetailsTab" :class-name="'tab-content-table'">
          <repairer-view-repairs-assigned :repairs="this.repairs"></repairer-view-repairs-assigned>
        </tab>
      </tabs>

    </div>
  </div>
</template>
